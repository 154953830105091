import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      light: {
        primary: '#0085c7',
        secondary: '#404040',
        tertiary: '#cccccf',
        accent: '#204165'
      },
      dark: {
        primary: '#0085c7',
        secondary: '#0B1C3D',
        tertiary: '#404050',
        accent: '#204165'
      }
    }
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});

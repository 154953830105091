let isEnabled = true;
let settings;

const STORAGE_KEY = 'SPOT_SETTINGS';

/**
 * Gets a setting from the settings store
 * @param {String} key The setting to get, for nested objects it is possible to use dot-notation as a property path eg `keyA.keyB.keyC`
 * @param {any} fallback The fallback value if this key is not stored yet
 * @returns The stored value or the fallback
 */
export const get = function (key, fallback = undefined) {
  if (!isEnabled) return null;
  const _settings = JSON.parse(JSON.stringify(settings));
  if (!key) return _settings;

  return key.split('.').reduce((acc, curr) => acc && acc[curr], _settings) || fallback;
}

/**
 * Sets a value in the settings store
 * @param {String} key The setting key, for nested objects it is possible to use dot-notation as a property path eg `keyA.keyB.keyC`
 * @param {any} value The value to store for the given key
 */
export const set = function (key, value) {
  if (!isEnabled || !key) return;

  const keyParts = key.split('.');
  let current = settings;

  keyParts.forEach((k, index) => {
    if (index === keyParts.length - 1) {
      current[k] = value;
    } else {
      current[k] = current[k] || {};
      current = current[k];
    }
  });

  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(settings));
}

/**
 * Deletes an entry from the settings store
 * @param {String} key The setting key, for nested objects it is possible to use dot-notation as a property path eg `keyA.keyB.keyC`
 */
export const deleteKey = function (key) {
  const keys = key.split('.');
  let current = settings;

  for (let i = 0; i < keys.length - 1; i++) {
    if (!Object.hasOwn(current, keys[i])) return false;
    current = current[keys[i]];
  }

  const result = delete current[keys[keys.length - 1]];
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(settings));

  return result;
}

export default {
  install: (app) => {
    app.mixin({
      computed: {
        $settings: useSettings
      }
    })
  }
}

export const useSettings = function () {
  if (window.localStorage) {
    try {
      settings = JSON.parse(window.localStorage.getItem(STORAGE_KEY) ?? '{}');
    } catch {
      settings = {};
    }
  } else {
    isEnabled = false;
  }

  return {
    get,
    set,
    deleteKey
  }
}
